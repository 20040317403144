body {
  font-family: 'Helvetica Neue', sans-serif;
  background-image: url("./assets/bakgrunn.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}


.mymodal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 10px;
  width: 80%;
}

.myoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

a {
  all: unset;
  cursor: pointer;
}

a[target="_blank"]:after {
  padding-left: 3px;
  content: url("./assets/external-link.svg");
}
